import React from "react"
import Layout from "../components/layout"
import img01 from "../images/vinos-01.jpg"
import DesktopWinesMenu from "../components/vinos/desktop/winesMenu";
import MobileWinesMenu from '../components/vinos/mobile/WinesMenu';
import DesktopVinosDetalle from "../components/vinos/desktop/VinosDetalle";
import MobileVinosDetalle from '../components/vinos/mobile/VinosDetalle';
import SimpleLocalize from "../components/SimpleLocalize";
import { BrowserView, MobileView } from 'react-device-detect';
import DesktopVideoHeader from "../components/common/desktop/VideoHeader";
import MobileVideoHeader from "../components/common/mobile/VideoHeader";

const IndexPage = (props) => (
	<SimpleLocalize {...props}>
	<Layout {...props}>
		<BrowserView>
			<DesktopVideoHeader
				youtubeurl={'https://youtube.com/embed/M96Jx8NOaGk?autoplay=1&controls=0&showinfo=0&autohide=1&rel=0&loop=1&mute=1&playlist=M96Jx8NOaGk&modestbranding=1'}
				backimg={img01}
				titleText={'vinos-title'}
				descriptionText={'el-terroir-que-envuelve'}
			/>
			<DesktopWinesMenu {...props} />
			<DesktopVinosDetalle {...props}/>
		</BrowserView>
		<MobileView>
			<MobileVideoHeader
				youtubeurl={'https://youtube.com/embed/M96Jx8NOaGk?autoplay=1&controls=0&showinfo=0&autohide=1&rel=0&loop=1&mute=1&playlist=M96Jx8NOaGk&modestbranding=1'}
				backimg={img01}
				titleText={'vinos-title'}
				descriptionText={'el-terroir-que-envuelve'}
			/>
			<MobileWinesMenu {...props} />
			<MobileVinosDetalle {...props}/>
		</MobileView>


	</Layout>
	</SimpleLocalize>
)

export default IndexPage